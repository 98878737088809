import React, { useState } from 'react';
import { Link, GatsbyLinkProps, graphql, useStaticQuery } from 'gatsby';
import { omit } from 'ramda';

import styled from 'styled-components/macro';

import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import Layout from 'src/components/Layout';
import SEO from 'src/components/Seo';
import CustomGatsbyLink from 'src/components/CustomGatsbyLink';
import StyledContentWrapper from 'src/components/StyledContentWrapper';
import { H1, H2, HeroSubtext, P, basePStyles } from 'src/components/Styled';

import * as colors from 'src/constants/colors';
import {
  TABLET_SCREEN_WIDTH_PX,
  DESKTOP_SCREEN_WIDTH_PX,
  HERO_HEIGHTS_BY_PAGE,
} from 'src/constants/layout';

const ON_SWITCH_PRODUCT_ANIMATION_DURATION_SECONDS = 0.4;

const Styled = {
  HeroSection: styled.section`
    height: ${HERO_HEIGHTS_BY_PAGE.products.height};
    min-height: ${HERO_HEIGHTS_BY_PAGE.products['min-height']};
    max-height: ${HERO_HEIGHTS_BY_PAGE.products['max-height']};
    color: ${colors.black};
    position: relative;
  `,
  HeroContentWrapper: styled(StyledContentWrapper)``,
  LogoImage: styled.img`
    margin-bottom: 2rem;
  `,
  BackgroundImage: styled(BackgroundImage)`
    min-height: 100%;
    background-position: bottom center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  Link: styled(props => (
    <CustomGatsbyLink
      {...omit(
        ['isActive', 'lightText'],
        props as GatsbyLinkProps<Record<string, unknown>>,
      )}
    />
  ))`
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-decoration: none;
    padding: 0.5rem 2.5rem;
    border-radius: 4px;
    color: ${colors.darkGray};
    background: ${colors.white};
    display: inline-block;
  `,
  ContentWrapper: styled(StyledContentWrapper)``,
  ProductsSection: styled.section`
    color: ${colors.black};
    padding: 5rem 0;
  `,
  H2: styled(H2)`
    text-align: center;
    margin-top: 0;
  `,
  ProductTabs: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  ProductTab: styled.div`
    display: flex;
    justify-content: center;
  `,
  ProductTabButton: styled.button`
    border: none;
    background: none;
    outline: none;
    font-family: Rubik;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      height: 8px;
      background: ${colors.darkBlue};
      opacity: ${props => (props.isActive ? 1 : 0)};
      left: ${props => (props.isActive ? 0 : '50%')};
      right: ${props => (props.isActive ? 0 : '50%')};
      transition: all ${ON_SWITCH_PRODUCT_ANIMATION_DURATION_SECONDS}s;
    }

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      font-size: 18px;
    }
    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      font-size: 20px;
    }
  `,
  ProductSubSection: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      flex-direction: row;
      margin-top: 3rem;
    }
  `,
  ProductInfosWrapper: styled.div`
    position: relative;
    flex: 2;
    height: 100%;
    display: flex;
    align-items: center;
    order: 2;
    margin-top: 1.5rem;
    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      order: 1;
      margin-top: 0;
    }
  `,
  ProductInfoWrapper: styled.div`
    position: ${props => (props.isActive ? 'relative' : 'absolute')};
    animation: ${props =>
      props.isActive
        ? `fadein ${ON_SWITCH_PRODUCT_ANIMATION_DURATION_SECONDS}s linear`
        : `fadeout ${ON_SWITCH_PRODUCT_ANIMATION_DURATION_SECONDS / 2}s linear`};
    animation-fill-mode: forwards;

    @keyframes fadein {
      from {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadeout {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  `,
  ProductImgWrapper: styled.div`
    order: 1;
    width: 100%;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      order: 2;
      flex: 3;
      padding-left: 2rem;
    }
  `,
  ProductImg: styled(Img)`
    width: 100%;
  `,
  ProductName: styled.h3`
    font-family: Foco, sans-serif; // TODO: buy and configure Foco font
    font-weight: bold;
    font-size: 26px;
    line-height: 38px;
    margin-top: 0;
    margin-bottom: 0.75rem;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      font-size: 30px;
    }
  `,
  ProductInfoSubHeader: styled.h4`
    ${basePStyles}
    font-weight: normal;
    font-size: 18px;
    line-height: 133%;
    margin-top: 0.75rem;
    margin-bottom: 0.6rem;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      font-size: 20px;
    }
  `,
};

const PRODUCTS: { name: string; imageId: string; mainTextContent: any }[] = [
  {
    name: 'Pallet Racks',
    imageId: 'palletrack',
    mainTextContent: (
      <>
       
        <Styled.ProductInfoSubHeader> 
        Types of available systems:
        </Styled.ProductInfoSubHeader>
        
        <P>
          Selective Rack - What most customers need...we have!
          <br />
          <br />
          Drive-In and Drive-Thru Racking
          <br />
          <br />
          Cantilever Racks
          <br />
          <br />
          Rack Accessories - Wire Decks, Guard Rails, Etc.
        </P>
      </>
    ),
  },
  {
    name: 'Boltless Shelving',
    imageId: 'boltless',
    mainTextContent: (
      <>
        <Styled.ProductInfoSubHeader>
        
        </Styled.ProductInfoSubHeader>
        <P>
       Rivet Shelving - Great for any hand stack products!
          <br />
          <br />
          Catwalk Mezzanine Shelving - go high with your storage!
          <br />
          <br />
          Shelving on Wheels - great for mobility and bulk storage
      
        </P>
      </>
    ),
  },
  {
    name: 'Specialized Warehouse Equipment',
    imageId: 'rollers',
    mainTextContent: (
      <>
          <Styled.ProductInfoSubHeader>
        All warehouse equipment big and small 
        </Styled.ProductInfoSubHeader>
        <P>
        Rolling Ladders
          <br />
          <br />
         Extendable Conveyors
          <br />
          <br />
         Pallet Jacks
          <br />
          <br />
         Warehouse Safety Railing
         <br />
         
        </P>
      </>
    ),
  },
];

const ProductsPage = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const data = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "blur rack.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        boltless: file(relativePath: { eq: "boltless.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        palletrack: file(relativePath: { eq: "pallet rack.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        rollers: file(relativePath: { eq: "rollers.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );
  const heroImgData = data.hero.childImageSharp.fluid;

  const products = PRODUCTS.map(product => ({
    ...omit(['imageId'], product),
    productImage: data[product.imageId].childImageSharp.fluid,
  }));

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;
    setActiveIndex(parseInt(value, 10));
  };

  return (
    <Layout page="products">
      <SEO title="Products" />
      <Styled.HeroSection>
        <Styled.BackgroundImage Tag="section" fluid={heroImgData}>
          <Styled.HeroContentWrapper>
            <H1>Our Products</H1>
            <HeroSubtext>
              The material that gets the job done
            </HeroSubtext>
            <Styled.Link to="/contact">Contact</Styled.Link>
          </Styled.HeroContentWrapper>
        </Styled.BackgroundImage>
      </Styled.HeroSection>
      <Styled.ProductsSection>
        <Styled.ContentWrapper>
          <Styled.H2>Here to solve all your warehouse needs.</Styled.H2>
          <Styled.ProductTabs>
            {PRODUCTS.map((product, index) => (
              <Styled.ProductTab key={product.name}>
                <Styled.ProductTabButton
                  isActive={index === activeIndex}
                  value={index}
                  onClick={handleClick}
                >
                  {product.name}
                </Styled.ProductTabButton>
              </Styled.ProductTab>
            ))}
          </Styled.ProductTabs>
          <Styled.ProductSubSection>
            <Styled.ProductInfosWrapper>
              {products.map((product, index) => (
                <Styled.ProductInfoWrapper
                  key={product.name}
                  isActive={activeIndex === index}
                >
                  <Styled.ProductName>{product.name}</Styled.ProductName>
                  {product.mainTextContent}
                </Styled.ProductInfoWrapper>
              ))}
            </Styled.ProductInfosWrapper>
            <Styled.ProductImgWrapper>
              <Styled.ProductImg fluid={products[activeIndex].productImage} />
            </Styled.ProductImgWrapper>
          </Styled.ProductSubSection>
        </Styled.ContentWrapper>
      </Styled.ProductsSection>
    </Layout>
  );
};

export default ProductsPage;
